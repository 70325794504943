import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Accordion from '../components/accordion'
import BlockContent from '../components/block-content'
import Figure from '../components/figure'
import { faqToAccordion, mapEdgesToNodes } from '../utils/helpers'
import Breadcrumbs from '../components/breadcrumbs'

const KundeservicePage = props => {
  const { data, errors } = props
  const page = data && data.page
  const questions = data && data.questions && mapEdgesToNodes(data.questions)
  var categories = []

  if (questions && questions.length) {
    // Group questions by category
    categories = [...questions.reduce((r, { category, ...question }) => {
      r.has(category.title) || r.set(category.title, {
        ...category,
        questions: []
      })

      r.get(category.title).questions.push({ ...question })

      return r
    }, new Map).values()]
  }

  const showIntercom = (e) => {
    e.preventDefault()

    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('show')
    }
  }

  return (
    <>
      <SEO page={page} />

      <Breadcrumbs crumbs={[ page ]} />
      <div className='bg-red-lighter header-offset'>
        <section className='pt-16 lg:pt-24 pb-20 lg:pb-32 px-5 lg:px-8 lg:px-0'>
          <div className='text-center lg:max-w-screen-md lg:mx-auto'>
            <h1 className='font-bold text-peach font-serif'>{page.title}</h1>
            <BlockContent blocks={page._rawShortDescription} className='lead' />
          </div>
        </section>

        <div className='bg-white'>
          <section id='faq' className='flex flex-col lg:flex-row'>
            <nav className='bg-peach-lighter lg:w-1/4 flex-shrink-0'>
              <div className='sticky p-8 py-12 lg:px-16 lg:pr-20 top-0 lg:flex lg:mt-8 flex-col items-center'>
                <div className='flex justify-center flex-row lg:flex-col flex-wrap'>
                  <h2 className='font-serif font-bold text-red mb-8 lg:hidden'>Ofte stilte spørsmål</h2>

                  <h4 className='text-lg text-red uppercase font-bold mb-6 lg:mb-8 flex-1 flex-shrink-0 min-w-full text-center whitespace-no-wrap'>Velg en kategori</h4>

                  {categories.map((category, i) => (
                    <Link className='mx-2 lg:mx-0 mb-2 lg:mb-4' to={`#${category.slug.current}`} key={i}>{category.title}</Link>
                  ))}
                </div>
              </div>
            </nav>

            <div className='bg-white p-5 py-8 md:p-8 lg:p-16 lg:w-3/4 max-w-screen-md lg:mx-auto'>
              <h2 className='font-serif font-bold text-red mb-10 hidden lg:block' aria-hidden>Ofte stilte spørsmål</h2>

              {categories.map((category, i) => (
                <Accordion
                  items={faqToAccordion(category.questions)}
                  className='mb-8 md:mb-16'
                  key={i}
                  id={category.slug.current}
                >
                  <h3 className='mb-6 font-serif'>{category.title}</h3>
                </Accordion>
              ))}
            </div>
          </section>
        </div>

        {page.introGrid && (
          <div className='bg-red-lighter py-20 lg:py-32 px-5 lg:px-8 lg:px-0'>
            <div className='lg:flex justify-center max-w-lg lg:max-w-screen-xl lg:mx-auto'>
              {page.introGrid.map((row, i) => (
                <div key={i} className='mb-12 lg:mb-0 lg:flex-1 lg:px-12 lg:text-center max-w-md'>
                  {row.image && <Figure node={row.image} className='w-24 mb-4 lg:mb-8 lg:mx-auto' />}
                  <h3 className='font-serif text-peach'>{row.title}</h3>
                  <BlockContent blocks={row.text} />

                  {i == 0 && (
                    <a href='mailto:hei@maja.no' className='read-more mt-8 text-sm'>Send oss en mail</a>
                  )}

                  {i == 1 && (
                    <button className='read-more mt-8 text-sm' onClick={showIntercom}>Chat med oss</button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default KundeservicePage

export const query = graphql`
  query {
    page: sanityCustomerServicePage(_id: {regex: "/(drafts.|)kundeservicePage/"}) {
      id
      title
      _rawShortDescription(resolveReferences:{maxDepth:100})
      introGrid {
        title,
        text: _rawText(resolveReferences:{maxDepth:100}),
        image {
          asset {
            id
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      seo {
        title
        description
      }
    }

    questions: allSanityFaqQuestion( filter: { category: { id: { ne: null } } } ) {
      edges {
        node {
          question
          _rawAnswer(resolveReferences:{maxDepth:100})
          slug {
            current
          }
          category {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`